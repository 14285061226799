import React, { useState,useEffect } from 'react';
import { Grid } from '@mui/material';
import './podcastTile.scss';
import { Link } from 'gatsby';


export default function PodcastTile({ data, basePath }) {
  const [podcastData,setPodcastData]=useState('')
  useEffect(()=>{
    setPodcastData({...data})
  },[])
  return (
    <>
    <Grid  className="episode-container">
      <Link  to={`/${basePath}/` + podcastData?.podcastSlug} locId="PodcastTileWrapper">
        <Grid container >
          <Grid item xl={3} lg={4} md={4} sm={3} xs={4}>
            <img
              src={podcastData?.thumbnailImage?.file.url+'?fm=webp&q=100'}
              alt={podcastData?.thumbnailImage?.description}
              locId="thumbnailImage"
              className="podcast-img"
            />
          </Grid>
          <Grid item xl={9} lg={8} md={8} sm={9} xs={8} className="podcast-text">
            <p className="episode-title" locId="podcastTitle">
              {podcastData?.podcastTitle}
            </p>
            {/* <p className="duration" locId="podcastDurationInMin">
              {podcastData?.podcastDurationInMin} MINS
            </p> */}
          </Grid>
          <Grid item xl={0} lg={0} md={0} sm={0} xs={0}></Grid>
        </Grid>
      </Link>
      </Grid>
    </>
  );
}
