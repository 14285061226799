import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
    "bg-main-donut": (data) => ({
        background: `url(${data?.leftDonutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
        '&::after':{
            background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) 0 0 no-repeat`,
        }
      }),
      "bg-capsules-right" :(data) => ({
        background: `url(${data?.capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
      })

}))