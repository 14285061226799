import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import './PodcastLandingContent.scss';
import PodcastTile from '../PodcastTiles';
import LoadMoreButton from '../../LoadMoreButton';

export default function PodcastLandingContent({ title, data, basePath, loadMoreButton, location }) {
  const numberOfTilePerPage = parseInt(process.env.GATSBY_NUMBER_OF_PODCAST_TILE_PER_PAGE);
  const urlParams=new URLSearchParams(location.search)
  let podcastList = data?.map((podcast) => {
    const dateinTimestamp = new Date(podcast.publishedDate).getTime();
    return { ...podcast, dateinTimestamp: dateinTimestamp };
  });
  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }

  const nowTime = new Date(Date.now()).getTime()
  const pastPodcastList = podcastList.filter((podcast)=>podcast.dateinTimestamp<=nowTime && podcast?.pageVisible)
  const podcasts = pastPodcastList.sort(comparePublishingDate);
  function splitArrayIntoChunksOfLen(arr, len) {
    let chunks = [],
      i = 0,
      n = arr?.length;
    while (i < n) {
      let chunkElement = arr.slice(i, (i += len));
      chunkElement?.splice(1, 0, {});
      chunkElement?.splice(6, 0, {});
      chunks.push(chunkElement);
    }
    return chunks;
  }
  podcastList = splitArrayIntoChunksOfLen(podcasts, 6);

  podcastList = [].concat.apply([], podcastList);

  const [colsCount, setColsCount] = useState(numberOfTilePerPage);
  const tilesAddMore = () => {
    let nextColsCount =
    podcastList.length > colsCount + numberOfTilePerPage ? colsCount + numberOfTilePerPage : podcastList.length;
    setColsCount(nextColsCount);
  };

  useEffect(() => {
    setColsCount(numberOfTilePerPage)
  }, [location.href])
  return (
    <>
      <Grid container className="podcast-landing-header-cls">
        {/* **********************  Podcast Content Header Starts  ********************** */}
        {/* Title Starts */}
        { podcastList?.length  ? (
          <>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={2} lg={2} md={0} sm={0} xs={0}></Grid>
          <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
            <h1 className="podcast-landing-title" locId="header">
              {title}
            </h1>
          </Grid>
        </Grid>
        {/* Title Ends */}

        {/* Podcast Tiles Ends */}
        <Grid container className="podcast-landing-container">
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={0} sm={0} xs={0}></Grid>
            <Grid item container xl={10} lg={10} md={12} sm={12} xs={12}>
              {podcastList.slice(0,colsCount).map((podcast) => (
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  {podcast?.contentful_id && (
                        <PodcastTile data={podcast} basePath={basePath}/>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        </>
        ) : (
          <></>
        )}
        {colsCount < podcastList?.length  ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <LoadMoreButton
              loadMoreButton={loadMoreButton}
              handleLoadMore={tilesAddMore}
              label="loadMoreButtonLabel"
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
