import React from 'react';
import Container from '@material-ui/core/Container';
import PodcastLandingContent from './PodcastLandingContent';
import './podcastlanding.scss';
import { myStyles } from './styles';
import clsx from 'clsx';

export default function PodcastLanding({podcastLanding, podcastContent, basePath, location }) {
  const classes = myStyles(podcastLanding);
  return (
    <>
      <div className="podcast-landing-main">
        <div className={clsx('podcast-main-bg', classes['bg-main-donut'])}>
          <div className={clsx('capsules-bg-right', classes['bg-capsules-right'])}>
            <Container maxWidth="xl" className="podcast-container-div">
              <PodcastLandingContent
                title={podcastLanding?.header}
                data={podcastContent}
                basePath={basePath}
                loadMoreButton={podcastLanding?.loadMoreButtonLabel}
                location={location}
              />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
