import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import PodcastLanding from '../components/PodcastLanding';
//import LoginPage from '../pages/login';

export default function PodcastlandingPage({ pageContext, location, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulPodcastLandingPage?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <PodcastLanding
          podcastLanding={data?.contentfulPodcastLandingPage}
          podcastContent={data?.allContentfulPodcast?.nodes}
          basePath={pageContext.basePath}
          location={location}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query PodcastLandingQuery($contentful_id: String, $locale: String) {
    contentfulPodcastLandingPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      header
      entryTitle
      contentful_id
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          description
          file {
            url
          }
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        file {
          url
        }
      }
      loadMoreButtonLabel
    }
    allContentfulPodcast(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        entryTitle
        node_locale
        podcastSlug
        podcastTitle
        pageVisible
        publishedDate(locale: "US")
        thumbnailImage {
          description
          file {
            url
          }
        }
        categoryList {
          categoryName
          contentful_id
          entryTitle
        }
        tagList {
          contentful_id
          entryTitle
          tagName
        }
      }
    }
  }
`;
